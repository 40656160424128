<template>
  <div>
    <div class="details">
      <div class="wrapper" v-loading="loading">
        <div class="lht-news-crumbs clearfix" v-if="type">
          <div @click="handleClick" class="lht-news-crumbs-item pull-left" style="cursor: pointer;">首页</div>
          <div
          class="lht-news-crumbs-item pull-left"
          style="cursor: pointer"
           @click="item != column.length ? handleRefresh(item): ''"
          v-for="(item, index) in column"
          :key="index">
            <span style="padding: 0 6px;"> > </span>
            <span class="span">{{item.name}}</span>
          </div>
          <div class="lht-news-crumbs-item pull-left">
            <span style="padding: 0 6px;"> > </span>
            <span class="span">详情</span>
          </div>
        </div>
        <h5 v-if="type">{{msg.title}}</h5>
        <div class="title" v-if="type">
          <span>
            <label>发表时间：</label>
            <span>{{msg.publishTime}}</span>
          </span>
          <span v-if="msg.source" style="padding-left: 20px;">
            <label>来源：</label>
            <span>{{msg.source}}</span>
          </span>
          <span v-if="msg.author" style="padding-left: 20px;">
            <label>作者：</label>
            <span>{{msg.author}}</span>
          </span>
        </div>
        <div class="content" v-html="msg.content">
        </div>
        <div class="file" v-if="msg.attFileList">
          <div class="file-item" v-for="(item, index) in msg.attFileList" :key="index" @click="handleUploadFile(item)">{{item.remark}}</div>
        </div>
        <div class="statement" v-if="msg.source">
          <p>声明:</p>
          1、凡本网注明“北京路航通科技发展有限公司”，所有自采新闻（含图片)，未经允许不得转载或镜像，授权转载应在授权范围内使用，并注明来源。<br/>
          2、部分内容转自其他媒体，转载目的在于传递更多信息，并不代表本网赞同其观点和对其真实性负责。<br/>
          3、如因作品内容、版权和其他问题需要同本网联系的，请在30日内进行。电话：010-68004560
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetNewsDetails, GetColumnIndependentDetails, uploadFileDownload } from '@/api/http'
export default {
  data () {
    return {
      height: 500,
      column: [],
      type: true,
      msg: {},
      loading: false
    }
  },
  created() {
    const height = (document.body.clientHeight || document.documentElement.clientHeight) - 450
    this.height = height
    console.log(this.height)
    if (this.$route.query.type) {
      this.type = false
      this.getDetailsType()
    } else {
      this.getDetails()
    }
  },
  methods: {
    getDetails () {
      this.loading = true
      GetNewsDetails({ id: this.$route.query.id }).then(res => {
        this.loading = false
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        // res.result.items.push({ name: '详情' })
        this.msg = res.result
        this.column = res.result.items
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    handleRefresh (item) {
      // console.log(item)
      this.$router.push({
        path: '/news',
        query: {
          id: this.column[0].id,
          content: this.column[0].name
        }
      })
    },
    handleClick () {
      this.$router.push({
        path: '/home'
      })
    },
    // 独立栏目
    getDetailsType() {
      this.loading = true
      GetColumnIndependentDetails({ id: this.$route.query.id }).then(res => {
        this.loading = false
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        this.msg = res.result
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    // 下载
    handleUploadFile (item) {
      const params = { path: item.path }
      uploadFileDownload(params).then(res => {
        const blob = new Blob([res.data])
        const fileName = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  min-height: calc(100vh - 400px);
  padding: 0px 0 100px;
  h5 {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin: 16px 0 30px;
  }
  .title {
    // margin: 0px 100px;
    text-align: center;
    border-top: 1px solid #E9E9E9;
    color: #999;
    font-size: 14px;
    line-height: 40px;
  }
  .content {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
    line-height: 30px;
  }
}
.lht-news-crumbs {
  color: #999999;
  padding: 30px 0;
  font-size: 14px;
  line-height: 1;
  .lht-news-crumbs-item:last-child {
    .span {
      color: #FF9852;
    }
  }
}
.file {
  margin-top: 40px;
}
.file-item {
  line-height: 30px;
  cursor: pointer;
  color: #155BDD;
}
.statement {
  margin-top: 40px;
  padding: 14px 10px 30px;
  border: 2px solid #999;
  line-height: 40px;
  color: #666;
  font-size: 14px;
  border-radius: 2px;
  p {
    font-weight: 700;
    font-size: 16px;
  }
}
</style>
